import { Splide } from '@splidejs/splide';

class ProductMedia extends HTMLElement {
  constructor() {
    super();

    this.childElements = [...this.children];
    this.childElements.forEach((child) => {
      child.classList.add('splide__slide');
    });
    this.slides = this.innerHTML;
    this.pagination = this.hasAttribute('pagination');
    this.useThumbnails = this.hasAttribute('thumbnails');

    this.generateCarousel = this.generateCarousel.bind(this);
  }

  connectedCallback() {
    this.generateCarousel();
  }

  generateCarousel() {
    this.innerHTML = '';

    let thumbnails = '';

    const mainSlider = document.createElement('div');
    mainSlider.classList.add('splide', 'lg:mb-16', 'mb-[38px]');
    const list = document.createElement('div');
    const track = document.createElement('div');
    list.classList.add('splide__list');
    track.classList.add('splide__track');
    list.innerHTML = this.slides;
    track.append(list);
    mainSlider.append(track);
    this.append(mainSlider);

    if (this.useThumbnails) {
      const thumbnailSlider = document.createElement('div');
      thumbnailSlider.classList.add('splide');
      const thumbList = document.createElement('div');
      const thumbTrack = document.createElement('div');
      thumbList.classList.add('splide__list', 'justify-center');
      thumbTrack.classList.add('splide__track');

      const thumbnailSlides = [...this.childElements].map(slide => {
        const thumbSlide = slide.cloneNode(true);
        // remove badges from thumbnail slides
        const badges = thumbSlide.querySelectorAll('.badge-text, .badge-image');
        badges.forEach(badge => badge.remove());
        thumbSlide.classList.remove('h-full', 'w-full');
        thumbSlide.classList.add(
          'w-[60px]',
          'h-[60px]',
          'opacity-50',
          'transition-opacity'
        );
        
        return thumbSlide;
      });

      thumbList.append(...thumbnailSlides);
      thumbTrack.append(thumbList);
      thumbnailSlider.append(thumbTrack);
      this.append(thumbnailSlider);

      thumbnails = new Splide(thumbnailSlider, {
        autoHeight: true,
        autoWidth: true,
        gap: 20,
        rewind: true,
        pagination: false,
        isNavigation: true,
        arrows: false,
        breakpoints: {
          1024: {
            gap: 10
          }
        }
      });
    }

    const main = new Splide(mainSlider, {
      arrows: true,
      pagination: this.pagination,
      autoWidth: true,
      perPage: 1,
      arrowPath:
        'M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z',
      classes: {
        arrows: 'splide__arrows ',
        arrow: 'splide__arrow h-10 w-10 rounded-none opacity-100',
        prev: '-left-[2.25rem] splide__arrow--prev',
        next: '-right-[2.25rem] splide__arrow--next',
        pagination: 'splide__pagination media__pagination',
        page: 'splide__pagination__page media-page'
      },
      breakpoints: {
        1024: {
          arrows: true
        }
      }
    });

    if (this.useThumbnails) {
      main.sync(thumbnails);
    }
    main.mount();

    if (this.useThumbnails) {
      thumbnails.mount();
    }
  }
}

if (!window.customElements.get('product-media')) {
  window.customElements.define('product-media', ProductMedia);
}
